<template>
  <div class="produce-list">
    <navbar
        :searchCategory="changeProductData.category_ids"
        :searchTheme="changeProductData.theme_id"
        :searchIsrush="changeProductData.is_rush"
        :searchKeyword="changeProductData.keyword"
    ></navbar>
    <div>
      <div class="container">
        <div class="title" v-if="leftTitle != ''">
          {{ leftTitle }}<span class="red">.</span>
        </div>
        <div class="main">
          <div class="catalogueTree" v-if="!isMobile">
            <h4>Narrow your search</h4>
            <!--价格区间的筛选-->
            <div class="price">
              <div class="title">
                PRICE <span>.</span>
                <span style="font-size: 12px">Radius:0 — 50</span>
              </div>
              <div class="content">
                <vue-slider
                    v-model="changeProductData.price"
                    :enable-cross="true"
                    style="margin-bottom: 10px"
                    max="50"
                ></vue-slider>
                <el-input-number
                    :controls="false"
                    v-model="changeProductData.price[0]"
                    placeholder="min"
                    style="width: 45%"
                    min="0"
                    max="50"
                ></el-input-number>
                &nbsp;-&nbsp;
                <el-input-number
                    :controls="false"
                    min="0"
                    max="50"
                    v-model="changeProductData.price[1]"
                    placeholder="max"
                    style="width: 45%"
                ></el-input-number>

                <el-button
                    @click="changePriceNum"
                    type="primary"
                    size="mini"
                    style="margin-top: 10px; width: 180px"
                >OK</el-button
                >
              </div>
            </div>
            <!--产品分类的筛选-->
            <div class="category">
              <div class="title"       @click="changeCategoryFold">
                CATEGORY <span>.</span>
                <i
                    class="el-icon-minus fold"
                    v-if="!foldCategory"

                ></i>
                <i
                    class="el-icon-plus unfold"
                    v-else
                ></i>
              </div>
              <div style="margin-left: 20px" v-if="isUpId">
                <div>
                  <el-button
                      type="text"
                      style="color: #000000; font-weight: bold"
                      @click="backCategory"
                  >
                    ⇦BACK
                  </el-button>
                </div>
                <div>
                  <el-button
                      type="text"
                      style="color: #000000; font-weight: bold"
                      @click="homeCategory"
                  >
                    ☰HOME
                  </el-button>
                </div>
              </div>
              <div class="content" v-if="!foldCategory">
                <div
                    class="categoryItem"
                    v-for="item in displayedCategoryItems"
                    :key="item.id + 'id'"
                    @click="subCategoryIem(item)"
                >
                  <span class="name">{{ item.title }}</span>
                  <span class="num">({{ item.product_count }})</span>
                </div>
              </div>
              <div v-if="!foldCategory">
                <button
                    v-if="categoryList.length > 8"
                    @click="toggleCategoryItems"
                    style="margin: 2px"
                >
                  {{ showAllCategory ? "Less Options" : "More Options" }}
                </button>
              </div>
            </div>
            <!--产品主题的筛选-->
            <div class="theme">
              <div class="title"    @click="changeThemeFold">
                THEME <span>.</span>
                <i
                    class="el-icon-minus fold"
                    v-if="!foldTheme"
                ></i>
                <i
                    class="el-icon-plus unfold"
                    v-else
                ></i>
              </div>
              <div class="content" v-if="!foldTheme">
                <div
                    @click="themeAll()"
                    style="
                    color: #181a1b;
                    font-size: 13px;
                    line-height: 30px;
                    margin-left: 20px;
                    cursor: pointer;
                    font-weight: bold;
                  "
                >
                  ALL THEME
                </div>
                <div
                    class="categoryItem"
                    v-for="item in displayedThemeItems"
                    :key="item"
                    @click="downTheme(item)"
                >
                  <span class="name">{{ item.title }}</span>
                  <span class="num">({{ item.product_count }})</span>
                </div>
              </div>
              <div v-if="!foldTheme">
                <button
                    v-if="themeList.length > 8"
                    @click="toggleThemeItems"
                    style="margin: 2px"
                >
                  {{ showAllTheme ? "Less Options" : "More Options" }}
                </button>
              </div>
            </div>
          </div>
          <div v-loading="loading" class="productList">
            <!--顶部的排序及分页-->
            <div class="listinfo">
              <el-row>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <div class="totalNum">{{ results }} results</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <el-select
                      v-model="changeProductData.order"
                      placeholder=""
                      size="mini"
                      style="float: left"
                      @change="changeOrder"
                      class="order-select"
                  >
                    <el-option
                        v-for="item in options1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                      v-model="changeProductData.pageSize"
                      placeholder="请选择"
                      size="mini"
                      style="float: left; width: 102px; margin-left: 10px"
                      @change="changeShowNum"
                      class="num-select"
                  >
                    <el-option
                        v-for="item in options2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <div class="view-type">
                    <div
                        class="gridView"
                        :class="{ isactive: gridViewActive }"
                        @click="gridView()"
                    >
                      <img
                          src="../../../assets/imgs/product/grid_view.png"
                          alt=""
                      />
                    </div>
                    <div
                        class="listView"
                        :class="{ isactive: listViewActive }"
                        @click="listView()"
                    >
                      <img
                          src="../../../assets/imgs/product/list-view.png"
                          alt=""
                      />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <el-pagination
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :current-page="changeProductData.page"
                      :page-size="changeProductData.pageSize" pager-count= "5"
                      :total="results"
                      layout=" prev, pager, next,jumper"
                  >
                  </el-pagination>
                </el-col>
                <el-col class="search-but" v-if="isMobile">
                  <el-button type="primary" @click="changeMenu">Narrow your search</el-button>
                </el-col>
              </el-row>
            </div>
            <div class="product-content">
              <div
                  class="gridViewProductItem"
                  v-for="item in productList"
                  :key="item.id"
                  v-show="gridViewActive"
              >
                <div class="img" @click="toProductDetail(item.id)">
                  <img :src="item.picture" alt=""/>
                </div>
                <div class="name" @click="toProductDetail(item.id)">
                  <span> {{ item.name }} </span>
                </div>
                <div class="index">Item # {{ item.code }}</div>
                <div class="price">
                  {{currency_symbol}}{{ item.min_price }} - {{currency_symbol}}{{ item.max_price }}
                </div>
                <div class="qty">MIN QTY: {{ item.min_quantity }}</div>
                <div class="" style="margin-top: 5px">
                  <el-checkbox-group
                      v-model="checkList"
                      size="mini"
                      @change="addToCompare(item)"
                  >
                    <el-checkbox :label="item.id">Choose</el-checkbox>
                  </el-checkbox-group>
                  <el-button
                      @click="collect(item.id)"
                      :type="item.user_collect == 1 ? 'danger' : ''"
                      icon="el-icon-star-off"
                      size="mini"
                      circle
                      style="position: absolute; right: 30px; bottom: 7px"
                  ></el-button>
                </div>
              </div>
              <div
                  class="listViewProductItem"
                  v-for="item in productList"
                  :key="item"
                  v-show="listViewActive"
              >
                <div class="leftbox">
                  <img :src="item.picture" @click="toProductDetail(item.id)" alt="" fit="cover"/>
                </div>
                <div class="rightbox">
                  <div class="name" @click="toProductDetail(item.id)">
                    <span> {{ item.name }} </span>
                  </div>
                  <div class="cardtext">
                    Let your brand shine while opening your beverage! Extra bright
                    white LED light. Features a split ring attachment. Just slide
                    the switch to turn light on/off. Size: 2 3/4"W x 1 1/4"H
                    Imprint: One color....
                  </div>
                  <div class="index">Item # {{ item.code }}</div>
                  <div class="price">
                    {{currency_symbol}}{{ item.min_price }} - {{currency_symbol}}{{ item.max_price }}
                  </div>
                  <div class="qty">MIN QTY: {{ item.min_quantity }}</div>
                  <div class="" style="margin-top: 5px">
                    <el-checkbox-group
                        v-model="checkList"
                        size="mini"
                        @change="addToCompare(item)"
                    >
                      <el-checkbox :label="item.id">Choose</el-checkbox>
                    </el-checkbox-group>
                    <el-button
                        @click="collect(item.id)"
                        :type="item.user_collect == 1 ? 'danger' : ''"
                        icon="el-icon-star-off"
                        size="mini"
                        circle
                        style="position: absolute; left: 250px; bottom: 15px"
                    ></el-button>
                  </div>
                </div>
              </div>
            </div>

            <el-pagination
                style="margin-top: 80px; text-align: right; width: 100%"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="changeProductData.page"
                :page-size="changeProductData.pageSize"
                :total="results"
                layout="total, prev, pager, next,jumper"
				pager-count= "5"
            >
            </el-pagination>
          </div>
        </div>
        <div v-if="isdrawer" style="width: 200px;height: 100px"></div><!--选择产品时的占位区域，解决最后一行产品无法选择的问题-->
      </div>
    </div>
    <!--选择Catalogue产品-->
    <div class="drawer" v-show="isdrawer">
      <div class="content" style="position: relative">
        <div id="product-swiper-container">
          <div style="display: flex; flex-wrap: wrap; align-items: center">
            <el-button
                size="mini"
                type="danger"
                plain
                @click="clearAll"
                style="margin: 10px"
            >Clear all</el-button
            >

            <enterinput style="margin-left: 10px" ref="enterinput"></enterinput>
            <el-input
                style="width: 200px; margin-left: 10px"
                type="text"
                class="price-input"
                min="0"
                title="Please enter the amount by which you would like to raise the price - percentage"
                placeholder="price-rise"
                @change="checkPercent()"
                v-model="percent"
            >
              <template slot="append">%</template>
            </el-input>
            <el-button
                size="mini"
                type="success"
                style="margin: 10px"
                @click="toplacard()"
            >Create Catalogue</el-button
            >
          </div>
          <div class="swiper-wrapper" style="position: relative !important">
            <div
                v-for="(item, index) in swiperList"
                :key="item.id"
                class="swiper-slide"
            >
              <el-button
                  size="mini"
                  @click="deleteProduct(index)"
                  class="delButton"
                  type="danger"
              >x</el-button
              >
              <div class="content">
                <div class="topbox">
                  <div class="img">
                    <img :src="item.picture" alt=""/>
                    <dev class="name">
                      <span>
                      {{ item.name }}
                      </span>
                    </dev>
                  </div>
                </div>
                <!--                <div class="" style="clear: both"></div>-->
                <div class="bottombox">

                  {{currency_symbol}}{{ item.min_price }} - {{currency_symbol}}{{ item.max_price }}
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
          <div class="swiper-button-next swp1"></div>
          <div class="swiper-button-prev swp2"></div>
        </div>
      </div>
    </div>

    <el-drawer
        title="Narrow your search"
        :visible.sync="isDrawerSearch"
        direction="ltr"
        size="80%">
      <div class="search-draw">
        <div class="catalogueTree" >
          <!--价格区间的筛选-->
          <div class="price">
            <div class="title">
              PRICE <span>.</span>
              <span style="font-size: 12px">Radius:$0 — $50</span>
            </div>
            <div class="content">
              <vue-slider
                  v-model="changeProductData.price"
                  :enable-cross="true"
                  style="margin-bottom: 10px"
                  max="50"
              ></vue-slider>
              <el-input-number
                  :controls="false"
                  v-model="changeProductData.price[0]"
                  placeholder="min"
                  style="width: 45%"
                  min="0"
                  max="50"
              ></el-input-number>
              &nbsp;-&nbsp;
              <el-input-number
                  :controls="false"
                  min="0"
                  max="50"
                  v-model="changeProductData.price[1]"
                  placeholder="max"
                  style="width: 45%"
              ></el-input-number>

              <el-button
                  @click="changePriceNum"
                  type="primary"
                  size="mini"
                  style="margin-top: 10px; width: 180px"
              >OK</el-button
              >
            </div>
          </div>
          <!--产品分类的筛选-->
          <div class="category">
            <div class="title"  @click="changeCategoryFold">
              CATEGORY <span>.</span>
              <i
                  class="el-icon-minus fold"
                  v-if="!foldCategory"

              ></i>
              <i
                  class="el-icon-plus unfold"
                  v-else

              ></i>
            </div>
            <div style="margin-left: 20px" v-if="isUpId">
              <div>
                <el-button
                    type="text"
                    style="color: #000000; font-weight: bold"
                    @click="backCategory"
                >
                  ⇦BACK
                </el-button>
              </div>
              <div>
                <el-button
                    type="text"
                    style="color: #000000; font-weight: bold"
                    @click="homeCategory"
                >
                  ☰HOME
                </el-button>
              </div>
            </div>
            <div class="content" v-if="!foldCategory">
              <div
                  class="categoryItem"
                  v-for="item in displayedCategoryItems"
                  :key="item.id + 'id'"
                  @click="subCategoryIem(item)"
              >
                <span class="name">{{ item.title }}</span>
                <span class="num">({{ item.product_count }})</span>
              </div>
            </div>
            <div v-if="!foldCategory">
              <button
                  v-if="categoryList.length > 8"
                  @click="toggleCategoryItems"
                  style="margin: 2px"
              >
                {{ showAllCategory ? "Less Options" : "More Options" }}
              </button>
            </div>
          </div>
          <!--产品主题的筛选-->
          <div class="theme">
            <div class="title"  @click="changeThemeFold">
              THEME <span>.</span>
              <i
                  class="el-icon-minus fold"
                  v-if="!foldTheme"
              ></i>
              <i
                  class="el-icon-plus unfold"
                  v-else
              ></i>
            </div>
            <div class="content" v-if="!foldTheme">
              <div
                  @click="themeAll()"
                  style="
                    color: #181a1b;
                    font-size: 13px;
                    line-height: 30px;
                    margin-left: 20px;
                    cursor: pointer;
                    font-weight: bold;
                  "
              >
                ALL THEME
              </div>
              <div
                  class="categoryItem"
                  v-for="item in displayedThemeItems"
                  :key="item"
                  @click="downTheme(item)"
              >
                <span class="name">{{ item.title }}</span>
                <span class="num">({{ item.product_count }})</span>
              </div>
            </div>
            <div v-if="!foldTheme">
              <button
                  v-if="themeList.length > 8"
                  @click="toggleThemeItems"
                  style="margin: 2px"
              >
                {{ showAllTheme ? "Less Options" : "More Options" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
import navbar from "../components/navbar/index.vue";
import {
  categoryData,
  themeData,
  productsList,
  collectProduct,
  getCategoryName,
  getThemeName,
} from "@/api/product.js";
import enterinput from "./enterinput.vue";
import { createCatalogue } from "@/api/placard";
export default {
  beforeRouteEnter(to,from,next){
    if(from.path === '/product-detail'){
      to.meta.isKeepAlive = 'isBack'
    }else{
      to.meta.isKeepAlive = ''
    }
    next()
  },
  components: {
    navbar,
    enterinput,
  },
  data() {
    return {
      isMobile: false,
      isDrawerSearch: false,
      loading: false,
      results: "",
      isNextShow: true,
      isPrevtShow: true,
      drawer: true,
      pricevalue: [0, 30],
      gridViewActive: true,
      listViewActive: false,
      currency_symbol:'$',
      is_rush: 0,
      pid: 0,
      isUpId: false,
      changeProductData: {
        price: [undefined, undefined],
        page: 1,
        pageSize: 60,
        order: "updatetime desc",
        // sort: "asc",
        category_id: "",
        category_ids: [],
        theme_id: "",
        keyword: localStorage.getItem("keyword"),
        token: localStorage.getItem("token"),
        is_rush: 0,
        price_type:localStorage.getItem("price_type"),
        is_sg:1
      },
      swiperList: [],
      options1: [
        {
          value: "updatetime desc",
          label: "New Product",
        },
        {
          value: "price asc",
          label: "Price: Low to High",
        },
        {
          value: "price desc",
          label: "Price: High to Low",
        },
        {
          value: "quantity asc",
          label: "Min QTY: Low to High",
        },
      ],
      value1: "New Product",
      options2: [
        {
          value: "60",
          label: "Show 60",
        },
        {
          value: "120",
          label: "Show 120",
        },
        {
          value: "180",
          label: "Show 180",
        },
      ],
      value2: "Show 60",
      checkList: [""],
      categoryList: [],
      themeList: [],
      productList: [],
      isdrawer: false,
      percent: "",
      isToken: "",
      isFromCategory: false,
      showAllCategory: false, // 隐藏/展示左侧分类数据
      showAllTheme: false, // 隐藏/展示左侧主题数据
      leftTitle: "", // 左侧顶部的标题
      foldCategory: false, //左侧分类的折叠状态
      foldTheme: false, //左侧主题的折叠状态
      allProductList:[],//所有商品列表
    };
  },
  watch: {
    "$route.query.category": {
      immediate: true,
      handler(newVal, oldVal) {
        let category_id = newVal[newVal.length - 1];
        this.changeProductData.category_id = category_id;
        this.changeProductData.category_ids = newVal;
        this.isFromCategory = true;
        this.getProductList();
        this.getcategoryData();
      },
    },
    "$route.query.theme": {
      immediate: true,
      handler(newVal, oldVal) {
        this.changeProductData.theme_id = newVal;
        // this.changeProductData.category_id = "";
        this.getProductList();
      },
    },
    "$route.query.is_rush": {
      immediate: true,
      handler(newVal, oldVal) {
        this.changeProductData.is_rush = newVal;
        this.is_rush = newVal;
        // this.changeProductData.category_id = "";
        this.getProductList();
        this.getcategoryData();
        this.getthemeData();
      },
    },
    "$route.query.keyword": {
      immediate: true,
      handler(newVal, oldVal) {
        this.changeProductData.keyword = newVal;
        // this.changeProductData.category_id = "";
        this.getProductList();
      },
    },
  },
  created() {
    if(window.screen.width>1024) {
      this.isMobile = false
    }else {
      this.isMobile = true
    }
  },
  mounted() {
    this.getProductSwiper();
    this.getthemeData();
    if (localStorage.getItem("token") && localStorage.getItem("token") != "") {
      this.isToken = true;
    } else {
      this.isToken = false;
    }
    this.getProductList();
  },
  computed: {
    displayedThemeItems() {
      return this.showAllTheme ? this.themeList : this.themeList.slice(0, 8);
    },
    displayedCategoryItems() {
      return this.showAllCategory
          ? this.categoryList
          : this.categoryList.slice(0, 8);
    },
  },
  methods: {
    changeMenu() {
      this.isDrawerSearch = true
    },
    changeCategoryFold() {
      this.foldCategory = !this.foldCategory;
    },
    changeThemeFold() {
      this.foldTheme = !this.foldTheme;
    },
    toggleThemeItems() {
      this.showAllTheme = !this.showAllTheme;
    },
    toggleCategoryItems() {
      this.showAllCategory = !this.showAllCategory;
    },
    getProductSwiper() {
      this.swiper = new Swiper("#product-swiper-container", {
        loop: false, // 无缝
        // autoplay: {
        //   //自动开始
        //   delay: 3000, //时间间隔
        //   disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        // },
        paginationClickable: true,
        slidesPerView: 4, // 一组三个
        spaceBetween: 8, // 间隔
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swp1",
          prevEl: ".swp2",
        },
        // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
        observer: true,
        observeParents: true,
        // 如果需要分页器
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true, // 分页器可以点击
        // },
        breakpoints: {
          //当宽度小于等于320
          320: {
            slidesPerView: 1,
            // spaceBetween: 50,
          },
          //当宽度小于等于480
          480: {
            slidesPerView: 2,
            // spaceBetween: 10,
          },
          //当宽度小于等于640
          640: {
            slidesPerView: 3, // 一组三个
            // spaceBetween: 10, // 间隔
          },
          //当宽度小于等于992
          //当宽度小于等于1200
          1200: {
            slidesPerView: 4, // 一组三个
            spaceBetween: 15, // 间隔
          },
        },
      });
    },
    collect(id) {
      if (
          localStorage.getItem("token") &&
          localStorage.getItem("token") != ""
      ) {
        collectProduct({ id: id, token: localStorage.getItem("token") }).then(
            (res) => {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.getProductList();
            }
        );
      } else {
        this.$confirm("Login Required", "Tips", {
          confirmButtonText: "OK",
          cancelButtonText: "Canecl",
          type: "warning",
        })
            .then(() => {
              this.$router.push({
                path: "login",
              });
            })
            .catch(() => {});
      }
    },
    deleteProduct(index) {
      let itemId = this.swiperList[index].id
      this.checkList.map((item,index)=>{
        if(item===itemId){
          this.checkList.splice(index,1)
        }
      })
      this.swiperList.splice(index, 1);
      // 当移除所有的选项时,关闭底部的选择弹窗
      if (this.swiperList.length == 0) {
        this.isdrawer = false;
      }
    },
    // 初始化better-scroll
    clearErrorMsg() {
      this.errorMsg = "";
    },
    subCategoryIem(item) {
      this.pid = item.id;
      this.changeProductData.category_id = this.pid;
      this.changeProductData.theme_id = "";
      this.getProductList();
      categoryData({ is_rush: this.is_rush, pid: this.pid }).then((res) => {
        this.categoryList = res.data.list;
        if (this.pid != 0) {
          this.isUpId = true;
          this.pid = res.data.pid;
        }
      });
      this.isDrawerSearch = false
    },
    gridView() {
      this.gridViewActive = true;
      this.listViewActive = false;
    },
    listView() {
      this.gridViewActive = false;
      this.listViewActive = true;
    },
    toProductDetail(id) {
      this.$router.push({
        path: "/product-detail",
        query: {
          id: id,
        },
      });
    },
    getcategoryData() {
      categoryData({ is_rush: this.is_rush, pid: this.pid }).then((res) => {
        this.categoryList = res.data.list;
      });
    },
    backCategory() {
      categoryData({ is_rush: this.is_rush, pid: this.pid }).then((res) => {
        this.changeProductData.category_id = this.pid;
        this.getProductList();
        this.categoryList = res.data.list;
        if (this.pid != 0) {
          this.isUpId = true;
          this.pid = res.data.pid;
        } else {
          this.isUpId = false;
        }
      });
    },
    homeCategory() {
      categoryData({ is_rush: this.is_rush, pid: 0 }).then((res) => {
        this.categoryList = res.data.list;
        this.changeProductData.category_id = 0;
        this.getProductList();
      });
    },
    getthemeData() {
      themeData({ is_rush: this.is_rush }).then((res) => {
        this.themeList = res.data;
      });
    },
    getCategoryName(category_id) {
      getCategoryName({ category_id: category_id }).then((res) => {
        this.leftTitle = res.data;
      });
    },
    getThemeName(theme_id) {
      getThemeName({ theme_id: theme_id }).then((res) => {
        this.leftTitle = res.data;
      });
    },

    async getCategoryThemeName(category_id, theme_id) {
      let category_name = "";
      let theme_name = "";
      try {
        // 使用 await 等待 getCategoryName 的返回结果
        let categoryRes = await getCategoryName({ category_id: category_id });
        category_name = categoryRes.data;

        // 使用 await 等待 getThemeName 的返回结果
        let themeRes = await getThemeName({ theme_id: theme_id });
        theme_name = themeRes.data;

        this.leftTitle = category_name + "&&" + theme_name;
      } catch (error) {
        // console.error('Error fetching category or theme name:', error);
      }
    },
    downTheme(item) {
      this.changeProductData.theme_id = item.id;
      this.changeProductData.category_id = "";
      this.getProductList();
      this.isDrawerSearch = false
    },
    themeAll() {
      this.changeProductData.theme_id = "";
      this.getProductList();
      this.isDrawerSearch = false
    },
    handleSizeChange(val) {
      // //console.log(`每页 ${val} 条`);
      this.changeProductData.pageSize = val;
      this.getProductList();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      // //console.log(`当前页: ${val}`);
      this.changeProductData.page = val;
      this.getProductList();
    },
    changeShowNum(val) {
      this.changeProductData.pageSize = val;
      this.getProductList();
    },
    changeOrder(val) {
      // this.changeProductData.pageSize = val;
      this.getProductList();
    },
    getProductList() {
      this.changeProductData.price_type = localStorage.getItem("price_type");
      if (!this.isFromCategory) {
        this.changeProductData.category_id = "";
      }
      this.loading = true;

      let changeProductData = this.changeProductData;
      this.leftTitle = "";
      if (changeProductData.is_rush == 1) {
        this.leftTitle = "Off-the-shelf products";
      }

      if (changeProductData.is_rush == 0) {
        this.leftTitle = "All products";
      }

      let category_id = changeProductData.category_id;
      let theme_id = changeProductData.theme_id;
      if (
          category_id != undefined &&
          theme_id != undefined &&
          category_id != "" &&
          theme_id != ""
      ) {
        this.getCategoryThemeName(category_id, theme_id);
      } else {
        if (category_id != "" && category_id != undefined) {
          this.getCategoryName(category_id);
        }

        if (theme_id != "" && theme_id != undefined) {
          this.getThemeName(theme_id);
        }
      }
      productsList(this.changeProductData).then((res) => {
        this.productList = res.data.list;
        this.currency_symbol = res.data.symbol;
        res.data.list.map(item=>{
          let optionItem = this.allProductList.find(b=>{b.id!==item.id})
          // console.log('optionItem',optionItem)
          if(!optionItem){
            this.allProductList.push(item)
          }
        })
        // console.log('allProductList',this.allProductList)
        this.results = res.data.count;
        this.loading = false;
      });
    },
    changePriceNum() {
      this.getProductList();
      this.isDrawerSearch = false
    },
    addToCompare(item) {
      // console.log(this.checkList)
      if (this.isToken) {
        this.isdrawer = true;
        const list = []
        this.checkList.forEach(a=>{
          this.allProductList.map(b=>{
            if(a === b.id ){
              let item = list.find(c=>c.id === a)
              // console.log('item',item)
              if(!item){
                list.push(b)
              }
            }
          })
        })
        // console.log('list',list)
        this.swiperList = list

        if (this.swiperList.length == 0) {
          this.isdrawer = false;
        }
        this.getProductSwiper();
      } else {
        this.$confirm("Login Required", "Tips", {
          confirmButtonText: "OK",
          cancelButtonText: "Canecl",
          type: "warning",
        })
            .then(() => {
              this.$router.push({
                path: "login",
              });
            })
            .catch(() => {
              this.checkList = [];
              // this.$message({
              //   type: "info",
              //   message: "Cancel Login",
              // });
            });
      }
    },
    clearAll() {
      this.swiperList = [];
      this.checkList = [];
      this.isdrawer = false;
    },
    toplacard(value) {
      const id = [];
      this.swiperList.map((item) => {
        id.push(item.id);
      });
      //console.log(this.$refs.enterinput.TagsAll);
      createCatalogue({
        ids: id.join(","),
        token: localStorage.getItem("token"),
        percent: this.percent,
        quantity: this.$refs.enterinput.TagsAll,
        price_type: localStorage.getItem("price_type"),
      }).then((res) => {
        if (res.code == 1) {

          let system = navigator.userAgent;
          let isAndroid =
              system.indexOf("Android") > -1 || system.indexOf("Adr") > -1; // android终端
          let isiOS = !!system.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

          // 使用
          if (isAndroid) {
            //android终端
            window.open(res.data.url, "_blank");
          } else if (isiOS) {
            //ios终端
            window.location.href = res.data.url;
          }else{
            window.open(res.data.url, "_blank");
          }
        }
      });
    },
    checkPercent() {
      // 只能输入数字
      if (!isNaN(this.percent)) {
        // 只能输入正整数
        if (this.percent < 0 || this.percent.includes(".")) {
          this.$message.error(
              "Profit point values do not meet specifications - not a positive integer"
          );
        } else {
          return true;
        }
      } else {
        this.$message.error(
            "Profit point values do not meet specifications - not a number"
        );
      }
      //清空输入框
      this.percent = "";
    },
  },
};
</script>

<style lang="less" scoped>

/*.el-main {
  padding: 40px 0 0 0;
}22*/
.el-button--primary {
  background-color: #38a7bb;
  border-color: #38a7bb;
}
.listinfo {
  position: absolute;
  left: 20px;
  height: auto;
}
.totalNum {
  color: #181a1b;
  font-family: Open Sans, sans-serif;
  font-size: 1.4167rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  //margin-bottom: 10px;
}
.productBox {
  background-color: #ccc;
}
.productItems {
  border: solid 1px red;
}
.container {
  height: calc(100vh - 302px);
  max-width: 1140px;
  margin: 0 auto;
  //margin-top: 20px;
  flex: none;
  .title {
    font-size: 24px;
    font-weight: bold;
    .red {
      color: red;
      font-size: 24px;
    }
  }
  .main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    // overflow: scroll;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    h4 {
      font-weight: bold;
    }

    .catalogueTree {
      flex: 0 0 auto;
      max-width: 100%;
      width: auto;
      overflow: auto;
      //position: relative;

      position: sticky;
      top: 0; /* 距离顶部的距离 */
      background-color: white;
      z-index: 1000; /* 保持在其他内容的上方 */

      max-height: calc(100vh - 230px);
      .price {
        border: 1px solid rgba(0, 0, 0, 0.125);
        width: 200px;
        .title {
          padding: 12px 20px;
          background-color: rgba(0, 0, 0, 0.03);
          height: 50px;
          line-height: 25px;
          color: #2c2f30;
          font-size: 14px;
          font-weight: bold;
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
          span {
            color: #da291c;
          }
        }
        .content {
          padding: 10px;
        }
      }
      .category {
        margin-top: 10px;
        border: 1px solid rgba(0, 0, 0, 0.125);
        width: 200px;
        .title {
          padding: 12px 20px;
          background-color: rgba(0, 0, 0, 0.03);
          height: 50px;
          line-height: 25px;
          color: #2c2f30;
          font-size: 14px;
          font-weight: bold;
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
          span {
            color: #da291c;
          }
        }
        .content {
          padding-top: 10px;
          .categoryItem {
            color: #181a1b;
            font-size: 13px;
            line-height: 30px;
            margin-left: 20px;
            cursor: pointer;
            .num {
              color: #666a6b;
              font-size: 11px;
              margin-left: 5px;
            }
          }
        }
        .content :hover {
          color: #38a7bb;
        }
      }
      .theme {
        margin-top: 10px;
        margin-bottom: 20px;
        border: 1px solid rgba(0, 0, 0, 0.125);
        width: 200px;
        .title {
          padding: 12px 20px;
          background-color: rgba(0, 0, 0, 0.03);
          height: 50px;
          line-height: 25px;
          color: #2c2f30;
          font-size: 14px;
          font-weight: bold;
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
          span {
            color: #da291c;
          }
        }
        .content {
          padding-top: 10px;
          .categoryItem {
            color: #181a1b;
            font-size: 13px;
            line-height: 30px;
            margin-left: 20px;
            cursor: pointer;
            .num {
              color: #666a6b;
              font-size: 11px;
              margin-left: 5px;
            }
          }
        }
        .content :hover {
          color: #38a7bb;
        }
      }
    }
    .listinfo {
      width: 85%;
      position: absolute;
      left: 20px;
      .totalNum {
        color: #181a1b;
        font-family: Open Sans, sans-serif;
        font-size: 1.4167rem;
        font-weight: 400;
        line-height: 1.5;
        margin: 0;
        text-align: left;
        margin-bottom: 10px;
      }
      .view-type {
        float: left;
        .isactive {
          background-color: #4d5051 !important;
        }
        .gridView {
          cursor: pointer;
          margin-left: 10px;
          width: 28px;
          height: 28px;
          background-color: #666a6b;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          position: relative;
          float: left;
          img {
            width: 13px;
            height: 13px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .listView {
          cursor: pointer;
          float: left;
          width: 28px;
          height: 28px;
          background-color: #666a6b;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          position: relative;
          img {
            width: 13px;
            height: 13px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    .productList {
      position: relative;
      display: flex;
      flex-flow: row wrap !important;
      justify-content: flex-start !important;
      flex-basis: 0;
      flex-grow: 1;

      /*overflow: scroll;
      height: calc(100vh - 320px);*/

      .gridViewProductItem {
        padding: 10px;
        transform: translate(0px, 80px);
        cursor: pointer;
        flex: none;
        word-wrap: break-word;
        background-clip: border-box;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        display: flex;
        flex-direction: column;
        min-width: 0;
        position: relative;
        margin: 5px;
        width: 210px;
        .img {
          /*width: 185px;
          height: 180px;
          display: flex;
          align-content: center;
          justify-content: center;
          overflow: hidden;*/

          width: 100%; /* 设置容器的宽度为100% */
          padding-bottom: 80%; /* 高度为宽度的75%（可以调整成你需要的比例） */
          position: relative; /* 设置容器为相对定位 */
          overflow: hidden; /* 隐藏超出部分 */
          img{
            position: absolute; /* 设置图片为绝对定位 */
            top: 0;
            left: 0;
            width: 100%; /* 图片宽度占满容器 */
            height: 100%; /* 图片高度占满容器 */
            object-fit: contain; /* 保持图片的宽高比，内容覆盖容器 */
            object-position: center; /* 将图片居中 */
          }
        }
        .name {
          color: #176f95;
          font-size: 16px;
          margin-left: 10px;
          height: 70px;
          //// width: 200px !important; //限制文本宽度
          //word-wrap: break-word; //自动换行
          //word-break: break-all; //自动换行（兼容字母）

          overflow: hidden;        //超出隐藏
          text-overflow: ellipsis; //溢出显示省略号
          display: -webkit-box;
          -webkit-line-clamp: 3;   //显示3行
          line-clamp: 1;
          -webkit-box-orient: vertical;
          cursor: pointer;
        }
        .name span {
        }
        .name :hover {
          color: #275366;
          text-decoration: underline;
        }
        .index {
          color: #666a6b !important;
          font-size: 13px;
          margin-left: 10px;
          margin-top: 10px;
        }
        .price {
          font-size: 13px;
          color: #666a6b !important;
          margin-left: 10px;
          line-height: 25px;
        }
        .qty {
          font-size: 13px;
          color: #666a6b !important;
          margin-left: 10px;
          line-height: 15px;
        }
      }
      .listViewProductItem {
        word-wrap: break-word;
        background-clip: border-box;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        display: flex;
        // flex-direction: column;
        min-width: 0;
        position: relative;
        width: calc(100% - 30px);
        transform: translate(0px, 80px);
        margin-left: 20px;
        margin-bottom: 25px;
        padding: 5px;
        .leftbox {
          display: flex;
          flex-direction: column;
          font-size: 16px;
          //padding: 0.5rem;
          word-wrap: break-word;
          background-clip: border;
          /*img {
            width: 117px;
          }*/


          width: 100%; /* 设置容器的宽度为100% */
          //padding-bottom: 30%; /* 高度为宽度的75%（可以调整成你需要的比例） */
          position: relative; /* 设置容器为相对定位 */
          overflow: hidden; /* 隐藏超出部分 */
          img{
            position: absolute; /* 设置图片为绝对定位 */
            top: 0;
            left: 0;
            width: 100%; /* 图片宽度占满容器 */
            height: 100%; /* 图片高度占满容器 */
            object-fit: contain; /* 保持图片的宽高比，内容覆盖容器 */
            object-position: center; /* 将图片居中 */
          }
        }
        .rightbox {
          flex: 0 0 83.33333333%;
          max-width: 83.33333333%;
          .name {
            background-color: transparent;
            color: #176f95;
            font-size: 16px;
            font-weight: 500;
          }
          .cardtext {
            color: #8e9191;
            font-family: Open Sans, sans-serif;
            font-size: 13px;
            font-weight: 400;
            line-height: 1.5;
            margin: 0;
            text-align: left;
            margin-top: 10px;
          }
          .index {
            color: #666a6b !important;
            font-size: 13px;
          }
          .price {
            font-size: 13px;
            color: #666a6b !important;
            line-height: 25px;
          }
          .qty {
            font-size: 13px;
            color: #666a6b !important;
            line-height: 15px;
          }
        }
      }
    }
  }
}
.left-scroll,
.right-scroll {
  width: 50%; /* 根据需要设置宽度 */
  height: 1000px; /* 根据需要设置高度 */
  overflow: hidden; /* 确保滚动时不会出现默认滚动条 */
}
::v-deep #el-drawer__title {
  //display: none;
}
//::v-deep .el-drawer__body {
//  background-color: rgba(44, 47, 48, 0.85);
//}
#product-swiper-container {
  position: relative;
  margin-top: 20px;
  // height: 200px;
  .swiper-slide {
    // transform: translate(-5px 0px);
    margin-top: 10px;
  }
  .content {
    border: 1px solid rgba(0, 0, 0, 0.125);
    background-color: #fff;
    padding: 10px;
    //padding-bottom: 20px;
    .topbox {
      margin-bottom: 15px;
      height: 50px;
    }
    .bottombox {
      margin-top: 15px;
      text-align: center;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-weight: 400;
    }
    .img {
      width: 50px;
      height: 50px;
      float: left;
    }
    .img img {
      max-width: 50px;
      max-height: 50px;
    }
    .name {
      float: left;
      color: #176f95;
      font-size: 14px;
      margin-left: 55px;
      margin-top: -50px;
      cursor: pointer;
      //width: 142px;

      width: 142px !important; //限制文本宽度
      word-wrap: break-word; //自动换行
      word-break: break-all; //自动换行（兼容字母）
      overflow: hidden; //超出隐藏
      text-overflow: ellipsis; //溢出显示省略号
      display: -webkit-box;
      -webkit-line-clamp: 3; //显示3行
      -webkit-box-orient: vertical;
    }
    .name span {
      background: linear-gradient(to right, #a19191, #999) no-repeat right
      bottom;
      background-size: 0 2px;
      // transition: background-size 1000ms;
    }
    .name span:hover {
      background-position-x: left;
      background-size: 100% 2px;
    }
    .index {
      color: #666a6b !important;
      font-size: 11px;
      margin-left: 10px;
      margin-top: 10px;
    }
    .price {
      font-size: 13px;
      color: #666a6b !important;
      margin-left: 10px;
      line-height: 25px;
    }
    .qty {
      font-size: 11px;
      color: #666a6b !important;
      margin-left: 10px;
      line-height: 15px;
    }
    .swiper-button-next {
      // position: absolute;
      top: 10px !important;
    }
  }
}
.drawer {
  //position: relative;
  height: 200px;
  background-color: rgba(44, 47, 48, 0.85);
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 10000;
  .delButton {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .content {
    max-width: 1140px;
    overflow: hidden;
    margin: 0 auto;
  }
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 14px;
  color: #a5a7a7;
}
.swiper-button-next {
  position: absolute;
  left: 97%;
  top: 10px;
  border: 1px solid #f0f0f0;
  background-color: #f0f0f0;
  width: 38px;
  height: 38px;
  border: 1px solid #666a6b;
}
.swiper-button-prev {
  position: absolute;
  left: 93%;
  top: 10px;
  border: 1px solid #f0f0f0;
  background-color: #f0f0f0;
  width: 38px;
  height: 38px;
  border: 1px solid #666a6b;
}
.swiper-button-disabled {
  // border: 1px solid #f0f0f0 !important;
  background-color: #f0f0f0;
  width: 38px;
  height: 38px;
  border: 1px solid #666a6b !important;
}
.fold,
.unfold {
  font-weight: bold;
  float: right;
  line-height: 25px;
  cursor: pointer;
}
</style>
<style lang="scss">
.el-scrollbar {
  > .el-scrollbar__bar {
    opacity: 1;
  }
}
</style>
