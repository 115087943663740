<template>
	<div>
		<div class="navbar">
			<div class="category" style="height: 60px">
				<div v-if="!isMobile">
					<SubMenu style="width: 765px; float: left;display: none" :menu-list="menuList" :props="defaultProps"
						@getmenu="onGetMenu" />
					<!--产品分类的筛选-->
					<el-cascader placeholder="Shop by Category" :options="categoryList" v-model="categoryId"
						:props="{ expandTrigger: 'hover' }" @change="getProductList('category')"
						@clearCheckedNodes="getProductList()" clearable filterable ref="categorySelect">
						<!-- 作用域插槽 自定义选项内容 -->
						<template #default="{ node, data }">
							<span :style="getLabelStyle(data)">
								{{ data.label }}
							</span>
						</template>
					</el-cascader>
					<!--产品主题的筛选-->
					<el-select v-model="themeId" style="margin:0 10px" @change="getProductList('theme')"
						@clear="getProductList()" placeholder="Shop by Theme" clearable filterable>
						<el-option v-for="item in themeList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
					<el-select v-model="isRush" style="margin:0 10px" @change="getProductList('is_rush')"
						placeholder="Product Availability"  filterable>
						<el-option v-for="item in isRushList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
					<!--现货产品的筛选-->
					<!-- <el-button type="text" @click="getProductList('all')" v-if="isRush == 1"><b>Ready to ship</b></el-button> -->
					<!--所有产品的筛选-->
					<!-- <el-button type="text" @click="getProductList('is_rush')" v-else><b>All Products</b></el-button> -->

				</div>
				<div v-if="isMobile">
					<span class="mobile-search">
						<i class="el-icon-more rotate-90" @click="changeMenu"></i>
					</span>
				</div>
				<!--关键词的筛选-->
				<el-input :nav_keyword="1231" placeholder="Product keywords" class="input-with-select select-style"
					@change="getProductList('keyword')" v-model="keyword"><i slot="suffix" class="el-icon-search"
						@click="getProductList('keyword')"
						style="font-size: 23px; line-height: 40px;cursor: pointer;"></i>
				</el-input>
			</div>
		</div>
		<div style="clear: both"></div>


		<el-drawer title="搜索" :visible.sync="isDrawer" direction="ltr" size="80%">
			<div>
				<!--产品分类的筛选-->
				<!--        <el-cascader-->
				<!--            placeholder="Shop by Category"-->
				<!--            :options="categoryList"-->
				<!--            v-model="categoryId"-->
				<!--            @change="getProductList('category')"-->
				<!--            @clearCheckedNodes="getProductList()"-->
				<!--            :emitPath="false"-->
				<!--            clearable filterable-->
				<!--            ref="categorySelect"-->
				<!--        ></el-cascader>-->
				<el-menu class="el-menu-vertical-demo">
					<el-submenu index="1">
						<template slot="title">
							<i class="el-icon-menu"></i>
							<span>Shop by Category</span>
						</template>
						<el-submenu v-for="(item, i) in categoryList" :key="'1-'+i" :index="'1-'+i">
							<template slot="title">{{item.label}}</template>
							<el-menu-item v-for="(name,idx) in item.children" :key="i+'-'+idx" :index="i+'-'+idx"
								@click="getProductMobileList('category',item.value,name.value)">
								{{name.label}}
							</el-menu-item>
						</el-submenu>
					</el-submenu>

					<el-submenu index="2">
						<template slot="title">
							<i class="el-icon-menu"></i>
							<span>Shop by Theme</span>
						</template>
						<el-menu-item v-for="(name,idx) in themeList" :key="'2-'+idx" :index="'2-'+idx"
							@click="getProductMobileList('theme',name.value)">
							{{name.label}}
						</el-menu-item>
					</el-submenu>
					<el-switch style="display: block ; height: 86px;padding: 25px 25px; " v-model="isAll"
						active-color="#13ce66" inactive-color="#aaaaaa"
						active-text="All Products" inactive-text="Ready to ship"
						@change="getProductMobileList('is_All')">
					</el-switch>
					<!-- <el-menu-item index="3" v-if="isRush == 1" @click="getProductMobileList('all')">
						<i class="el-icon-menu"></i>
						<span slot="title">Ready to ship</span>
					</el-menu-item>
					<el-menu-item index="4" v-else @click="getProductMobileList('is_rush')">
						<i class="el-icon-menu"></i>
						<span slot="title">All Products </span>
					</el-menu-item> -->
				</el-menu>
				<!--产品主题的筛选-->
				<!--        <el-select v-model="themeId" style="margin:0 10px"-->
				<!--                   @change="getProductList('theme')"-->
				<!--                   @clear="getProductList()"-->
				<!--                   placeholder="Shop by Theme"-->
				<!--                   clearable filterable>-->
				<!--          <el-option-->
				<!--              v-for="item in themeList"-->
				<!--              :key="item.value"-->
				<!--              :label="item.label"-->
				<!--              :value="item.value">-->
				<!--          </el-option>-->
				<!--        </el-select>-->

				<!--现货产品的筛选-->
				<!--        <el-button type="text"  @click="getProductList('all')" v-if="isRush == 1"><b>Ready to ship</b></el-button>-->
				<!--所有产品的筛选-->
				<!--        <el-button type="text" @click="getProductList('is_rush')" v-else><b>All Products</b></el-button>-->
			</div>
		</el-drawer>
	</div>


</template>

<script>
	import {
		categoryThemeList
	} from "@/api/product.js";
	import SubMenu from "./subMenu.vue";
	export default {
		components: {
			SubMenu,
		},
		options: {
			styleIsolation: 'shared'
		}, //加上这一行
		props: ['searchCategory', 'searchTheme', 'searchIsrush', 'searchKeyword'],
		data() {
			return {
				inputText: '',
				defaultProps: {
					children: "children",
					title: "title",
					index: "path",
				},
				menuList: [],
				categoryList: [],
				themeList: [],
				categoryId: [],
				themeId: '',
				isRush: null,
				isAll: false,
				keyword: '',
				pid: '',
				isMobile: false,
				isDrawer: false,
				isRushList: [{
						label: 'Ready to ship',
						value: 1,
					},
					{
						label: 'All Products',
						value: 0,
					},
				],
			};
		},
		created() {
			if (window.screen.width > 768) {
				this.isMobile = false
			} else {
				this.isMobile = true
			}
		},
		mounted() {
			if (this.$props.searchCategory) {
				let category_arr = this.$props.searchCategory;

				if (category_arr.length > 1) {
					category_arr = category_arr.map(n => parseInt(n, 10));
				}

				this.categoryId = category_arr
			}
			if (this.$props.searchTheme) {
				this.themeId = parseInt(this.$props.searchTheme);
			}
			if (this.$props.searchIsrush) {
				this.isRush = parseInt(this.$props.searchIsrush)

			}
			if (parseInt(this.$props.searchIsrush) == 0) {
				this.isAll = true
			
			}
			if (parseInt(this.$props.searchIsrush) == 1) {
				this.isAll = false
			
			}
			if (this.$props.searchKeyword) {
				this.keyword = this.$props.searchKeyword
			}
			this.getCategoryThemeList();
		},
		methods: {
			// 根据 type 返回不同的样式
			getLabelStyle(data) {
				if (data.type === 'all') {
					return 'font-weight: bolder'; // 加粗
				} else if (data.type === 'one') {
					// return 'color: red;'; // 变红
				}
				return ''; // 默认样式
			},
			changeMenu() {
				this.isDrawer = true
			},
			handleOpen(key, keyPath) {
				//console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				//console.log(key, keyPath);
			},
			onGetMenu(item) {
				this.pid = item.id
				this.$router.push({
					path: 'product-list',
					query: {
						id: item.id,
						keyword: this.inputText
					},
				});
				localStorage.setItem("pid", item.id)
				localStorage.setItem("keyword", this.inputText)
				// console.log(item.id)
			},
			getCategoryThemeList() {
				categoryThemeList().then((res) => {
					this.categoryList = res.data.category;
					this.themeList = res.data.theme;
					/*var theme =[]
					res.data.theme.forEach((item) => {
					  theme.push({
					    'id':item.id+'T',
					    'title': item.title,
					  })
					});*/
					// this.menuList = [
					//   {
					//     id: 100000,
					//     title: "Shop by category",
					//     path: "/demo2",
					//     children: res.data.category,
					//   },
					//   {
					//     id: 100001,
					//     title: "Rush Products",
					//     path: "/demo2",
					//   },
					//   {
					//     id: 100002,
					//     title: "Shop by Theme",
					//     path: "/demo2",
					//     children: theme,
					//   },
					// ];
					// //console.log(this.menuList)
				});
			},
			showPwd() {
				this.$router.push({
					path: 'product-list',
					query: {
						id: this.pid,
						keyword: this.inputText
					},
				});
				localStorage.setItem("keyword", this.inputText)
			},
			getProductList1(type = '') {
				let query = {};
				if (this.categoryId != []) {
					let categoryId = this.categoryId[this.categoryId.length - 1]
					query.category = categoryId;
				}
				if (this.themeId != '') query.theme = this.themeId;
				if (type == 'is_rush') {
					this.isRush = 1;
					query.is_rush = 1;
				}
				if (type == 'all') {
					this.isRush = 0;
					query.is_rush = 0;
				}
				if (this.keyword != '') query.keyword = this.keyword;

				this.$router.push({
					path: 'product-list',
					query: query,
				});
			},

			getProductList(type) {
				let query = {};

        let categoryId = this.categoryId;
        let themeId = this.themeId;
        let isRush   = this.isRush;
        let keyword  = this.keyword;

        query = {
          'is_rush' : isRush,
          'keyword' : keyword,
          'theme'   : themeId,
          'category': categoryId,
        };

				if (type == 'category' && categoryId != []) {
					this.themeId = '';

					// let categoryId = this.categoryId[this.categoryId.length - 1]
					query = {
						'category': categoryId,
            'keyword' : keyword,
            'is_rush' : isRush,
					};
				}
				if (type == 'theme' && themeId != '') {
					this.categoryId = [];
					query = {
						'theme': themeId,
            'keyword' : keyword,
            'is_rush' : isRush,
					};
				}

				if (this.categoryId.length == 1) {
					this.$nextTick(() => {
						this.$refs.categorySelect.dropDownVisible = false;
					});
				}
				this.$refs.categorySelect.dropDownVisible = false;
				this.$router.push({
					path: 'product-list',
					query: query,
				});
			},
			getProductMobileList(type, categoryId1, categoryId2) {
				let query = {};
				if (type == 'category') {
					this.categoryId = [categoryId1, categoryId2]
				} else if (type == 'theme') {
					this.themeId = categoryId1
				}

				if (type == 'category' && this.categoryId != []) {
					this.themeId = '';
					this.isRush = 0;
					this.keyword = '';

					// let categoryId = this.categoryId[this.categoryId.length - 1]
					query = {
						'category': this.categoryId
					};
				}
				if (type == 'theme' && this.themeId != '') {
					this.categoryId = [];
					this.isRush = 0;
					this.keyword = '';
					query = {
						'theme': this.themeId,
						'category': ''
					};
				}
				if (type == 'is_All') {
					this.categoryId = [];
					this.themeId = '';
					this.keyword = '';
					this.isDrawer = false
					if(this.isAll){
						query = {
							'is_rush': 0,
							'category': ''
						};
					}else{
						query = {
							'is_rush': 1,
							'category': ''
						};
					}

				}
				// if (type == 'is_rush') {
				// 	this.categoryId = [];
				// 	this.themeId = '';
				// 	this.keyword = '';
				// 	this.isDrawer = false
				// 	this.isRush = 1;
				// 	query = {
				// 		'is_rush': 1,
				// 		'category': ''
				// 	};
				// }
				// if (type == 'all') {
				// 	this.categoryId = [];
				// 	this.themeId = '';
				// 	this.keyword = '';

				// 	this.isRush = 0;
				// 	this.isDrawer = false
				// 	query = {
				// 		'is_rush': 0,
				// 		'category': ''
				// 	};
				// }
				if (type == 'keyword' && this.keyword != '') {
					this.categoryId = [];
					this.themeId = '';
					this.isRush = 0;

					query = {
						'keyword': this.keyword,
						'category': ''
					};
				}
				this.isDrawer = false;
				this.$router.push({
					path: 'product-list',
					query: query,
				});
			},


		},
	};
</script>



<style lang="less">
	.navbar {
		max-width: 1140px;
		width: 100%;
		margin: 0 auto;

		.navbar {
			.category {
				float: left;
			}

			.search {}
		}
	}

	.el-menu.el-menu--horizontal {
		border: none;
	}

	// .el-menu--popup-bottom-start{
	//   top: 280px;
	// }
	::v-deep .is-active {
		border-bottom: none !important;
		color: none !important;
		/* 重置选中项的颜色 */
		background-color: none !important;
		/* 重置选中项的背景色 */
	}

	::v-deep .is-active {
		color: none !important;
		/* 重置选中项的颜色 */
		background-color: none !important;
		/* 重置选中项的背景色 */
	}
</style>
<style scoped>
	/* 使用 ::v-deep 来覆盖 Element UI 的默认样式 */
	::v-deep .el-select .el-input__inner {
		border-color: #606266;
		/* 自定义边框颜色 */
	}

	::v-deep .el-select:hover .el-input__inner {
		border-color: #409EFF;
		/* 悬停时的边框颜色 */
	}

	::v-deep .el-cascader .el-input__inner {
		border-color: #606266;
		/* 自定义边框颜色 */
	}

	::v-deep .el-cascader:hover .el-input__inner {
		border-color: #409EFF;
		/* 悬停时的边框颜色 */
	}

	::v-deep .el-input .el-input__inner {
		border-color: #606266;
		/* 自定义边框颜色 */
	}

	::v-deep .el-input:hover .el-input__inner {
		border-color: #409EFF;
		/* 悬停时的边框颜色 */
	}
</style>